body::before {
  content: "(xsm: 479px, sm: 750px, md: 768px, lg: 970px, xl: 1280px)";
  display: none; }

.presentation-page .content-row {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  padding: 5px 0 22px 0;
  flex-grow: 1;
  flex-shrink: 0; }
  .presentation-page .content-row .left-side {
    border-right: 1px solid #BDBDBD;
    width: 530px;
    margin-top: 8px;
    flex-shrink: 0; }
    .presentation-page .content-row .left-side .slick-slider {
      margin: 30px auto 50px;
      max-width: 550px; }
      .presentation-page .content-row .left-side .slick-slider .slick-track {
        display: flex;
        align-items: center; }
      .presentation-page .content-row .left-side .slick-slider .slick-slide {
        padding: 18px;
        float: none; }
      .presentation-page .content-row .left-side .slick-slider .slick-slide .image {
        padding: 10px; }
      .presentation-page .content-row .left-side .slick-slider .slick-slide img {
        display: block;
        margin: auto;
        object-fit: contain;
        max-width: 100%;
        max-height: 350px; }
      .presentation-page .content-row .left-side .slick-slider .slick-slide img.slick-loading {
        border: 0; }
      .presentation-page .content-row .left-side .slick-slider .slick-dots {
        margin-left: 0; }
      .presentation-page .content-row .left-side .slick-slider .slick-thumb {
        bottom: -45px; }
      .presentation-page .content-row .left-side .slick-slider .slick-thumb li {
        width: 60px;
        height: 45px; }
      .presentation-page .content-row .left-side .slick-slider .slick-vertical .slick-slide {
        height: 180px; }
      .presentation-page .content-row .left-side .slick-slider .slick-dots li button:before {
        font-size: 9px; }
      .presentation-page .content-row .left-side .slick-slider .slick-arrow {
        transform: rotate(-90deg);
        transform-origin: center center;
        fill: #4F4F4F;
        width: 36px;
        height: 36px; }
      .presentation-page .content-row .left-side .slick-slider .slick-prev:before {
        content: none; }
      .presentation-page .content-row .left-side .slick-slider .slick-next {
        transform: rotate(90deg); }
        .presentation-page .content-row .left-side .slick-slider .slick-next:before {
          content: none; }
    .presentation-page .content-row .left-side .description {
      margin: 0 52px;
      padding-bottom: 30px; }
      .presentation-page .content-row .left-side .description:after {
        content: '';
        display: block;
        width: 70%;
        height: 1px;
        background: #bdbdbd;
        margin-top: 30px; }
      .presentation-page .content-row .left-side .description ul, .presentation-page .content-row .left-side .description ol {
        margin: 18px 0 20px 0;
        padding: 0 40px; }
      .presentation-page .content-row .left-side .description ul li {
        list-style-type: disc; }
      .presentation-page .content-row .left-side .description ol li {
        list-style-type: decimal; }
    .presentation-page .content-row .left-side .profile {
      display: flex;
      margin: 0 52px 20px; }
      .presentation-page .content-row .left-side .profile .avatar img {
        border-radius: 50%;
        width: 74px;
        height: 74px;
        min-width: 74px;
        min-height: 74px; }
      .presentation-page .content-row .left-side .profile .profile-details {
        padding-left: 22px;
        font-size: 14px; }
        .presentation-page .content-row .left-side .profile .profile-details div {
          margin-bottom: 4px; }
        .presentation-page .content-row .left-side .profile .profile-details .profile-name {
          font-size: 18px;
          margin-bottom: 8px;
          color: #323232;
          font-family: "brandon", sans-serif;
          font-weight: 400; }
    .presentation-page .content-row .left-side.left-side--wide {
      width: 840px; }
    .presentation-page .content-row .left-side.left-side--full {
      width: 100%; }
  .presentation-page .content-row .maps-container {
    margin: 0 52px;
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    align-content: flex-start; }
    .presentation-page .content-row .maps-container .single-map {
      display: block;
      width: 142px;
      height: 80px;
      box-sizing: border-box;
      pointer-events: all;
      cursor: pointer;
      margin: 8px;
      border-radius: 4px;
      overflow: hidden;
      flex-shrink: 0; }
      .presentation-page .content-row .maps-container .single-map .scene-background {
        display: block;
        width: 100%;
        height: 100%;
        transition: all 0.2s; }
        .presentation-page .content-row .maps-container .single-map .scene-background::after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0);
          position: relative;
          top: 0;
          left: 0;
          transition: all 0.2s; }
      .presentation-page .content-row .maps-container .single-map .scene-name {
        position: relative;
        top: -50%;
        width: 100%;
        color: #000;
        font-size: 14px;
        font-weight: 400;
        transform: translateY(-50%);
        overflow: hidden;
        height: 0;
        transition: all 0.2s;
        text-overflow: ellipsis;
        padding: 0 5px;
        display: flex;
        align-items: center; }
      .presentation-page .content-row .maps-container .single-map.isSelected .scene-background::after, .presentation-page .content-row .maps-container .single-map:hover .scene-background::after {
        background: rgba(255, 255, 65, 0.63); }
      .presentation-page .content-row .maps-container .single-map.isSelected .scene-name, .presentation-page .content-row .maps-container .single-map:hover .scene-name {
        height: 50px; }

.presentation-page .details-box {
  display: flex;
  justify-content: flex-end;
  padding: 0 22px 18px;
  font-size: 12px;
  color: #828282;
  flex-shrink: 0; }
  .presentation-page .details-box > div {
    display: flex;
    margin-left: 18px; }
  .presentation-page .details-box svg {
    margin-top: 1px;
    margin-right: 8px;
    display: block; }

.presentation-page footer .container {
  max-width: 120rem; }

@media (min-width: 850px) {
  .presentation-page .content-row .left-side.left-side--wide {
    width: 530px; }
  .presentation-page .content-row .left-side .slick-slider {
    margin: 30px 30px 50px; } }

@media (max-width: 849px) {
  .presentation-page .content-row .left-side.left-side--wide {
    width: 100%; } }

@media (max-width: 849px) {
  .presentation-page .content-row {
    flex-direction: column; }
    .presentation-page .content-row .maps-container {
      margin: 0 16px; }
    .presentation-page .content-row .left-side {
      width: auto; }
      .presentation-page .content-row .left-side .slick-slider {
        margin: 0 16px 30px; }
        .presentation-page .content-row .left-side .slick-slider .slick-arrow {
          width: 0; }
      .presentation-page .content-row .left-side .description {
        margin: 8px 16px; }
      .presentation-page .content-row .left-side .profile {
        margin: 0 16px 8px; }
      .presentation-page .content-row .left-side.left-side--wide {
        width: auto; }
  .presentation-page .modal .viewing-modal {
    width: 100%; }
    .presentation-page .modal .viewing-modal .links-row .links-detail {
      flex-grow: 0;
      flex-shrink: 1;
      min-width: 0; }
      .presentation-page .modal .viewing-modal .links-row .links-detail > div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
    .presentation-page .modal .viewing-modal .links-row .links-cta {
      flex-grow: 1;
      flex-shrink: 0; }
    .presentation-page .modal .viewing-modal .links-row .links-textfield {
      width: auto !important; }
    .presentation-page .modal .viewing-modal .links-row:nth-child(1) .links-cta {
      flex-basis: 120px;
      width: 120px; } }

.footer--presentation {
  box-sizing: border-box;
  border: none; }
  .footer--presentation .row {
    border-top: 1px solid #d9d9d9;
    padding: 0 30px; }
  .footer--presentation .menu-items {
    flex-grow: 1; }
  .footer--presentation .copyright-container {
    display: flex; }
    .footer--presentation .copyright-container .copyright {
      padding-top: 2px; }
  .footer--presentation .social-buttons a {
    margin-left: 8px; }
  @media only screen and (min-width: 1px) and (max-width: 64em) {
    .footer--presentation .logo-container {
      flex-grow: 1; } }
  @media only screen and (max-width: 48em) {
    .footer--presentation .copyright {
      display: none; } }

.link {
  padding: 11px 32px;
  color: #666666 !important; }
