body::before {
  content: "(xsm: 479px, sm: 750px, md: 768px, lg: 970px, xl: 1280px)";
  display: none; }

.privacy-loader {
  height: 90vh; }

#privacy {
  padding-top: 73px;
  padding-bottom: 65px;
  float: none; }
  #privacy h1 {
    color: #222 !important;
    padding: 24px; }
  #privacy .description {
    padding: 24px;
    font-family: Roboto, Arial, Helvetica Neue, Helvetica, sans-serif;
    font-size: 1.25rem;
    color: #676765; }
  #privacy ol.numbered-list {
    counter-reset: a; }
    #privacy ol.numbered-list li {
      display: block; }
      #privacy ol.numbered-list li:before {
        content: counters(a, ".") ".";
        counter-increment: a; }
      #privacy ol.numbered-list li.numbered-list-title {
        font-family: Roboto, Arial, Helvetica Neue, Helvetica, sans-serif;
        font-size: 1.6875rem;
        line-height: 1.111111111;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #222;
        margin-top: 30px; }
      #privacy ol.numbered-list li.numbered-list-first {
        margin-top: 0; }
    #privacy ol.numbered-list ol {
      margin-top: 22.5px; }
    #privacy ol.numbered-list span {
      display: block; }
  #privacy ol.lettered-list {
    counter-reset: b;
    list-style-type: lower-latin; }
    #privacy ol.lettered-list > li:before {
      content: counter(b, lower-latin) ")";
      counter-increment: b; }
  #privacy p:first-child {
    padding-left: 40px; }
  #privacy p, #privacy li {
    font-family: 'Roboto', Arial, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
    font-size: 1.125rem;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 30px;
    margin-bottom: 15px;
    color: #676765; }
  @media (min-width: 769px) {
    #privacy {
      padding-top: 94px; } }
