body::before {
  content: "(xsm: 479px, sm: 750px, md: 768px, lg: 970px, xl: 1280px)";
  display: none; }

.pricing .hero {
  height: 220px;
  text-align: center;
  background-image: url("/static/home/real-estate/background.jpg");
  background-position: center center; }
  .pricing .hero h1 {
    margin-top: 40px; }

.pricing .banner {
  height: 95px;
  background-color: #072D37; }

.pricing .benefits-section {
  max-width: 1280px;
  text-align: center;
  padding: 40px 0 40px 0;
  margin: 0 auto; }
  .pricing .benefits-section .container {
    max-width: 800px;
    margin: 0 auto; }
    @media (min-width: 1280px) {
      .pricing .benefits-section .container {
        max-width: none; } }
  .pricing .benefits-section h1 {
    color: #333333;
    margin-bottom: 42px; }
  .pricing .benefits-section .benefit-item {
    margin-bottom: 30px;
    padding: 0 20px 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: initial;
    text-align: left; }
    @media (min-width: 1280px) {
      .pricing .benefits-section .benefit-item {
        flex-direction: column;
        justify-content: center;
        text-align: center; } }
  .pricing .benefits-section .column {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: start; }
  .pricing .benefits-section .icon {
    height: 40px;
    width: 60px;
    min-width: 60px;
    max-width: 60px;
    fill: #1F97BC !important;
    margin: 0 20px 0 20px; }
  .pricing .benefits-section .cls-2 {
    fill: #008aae; }
  .pricing .benefits-section .cls-3 {
    fill: #fff100; }
  .pricing .benefits-section .description {
    color: #333333; }
    @media (min-width: 1280px) {
      .pricing .benefits-section .description {
        margin-top: 16px;
        flex: 2; } }

.pricing .offers-section {
  text-align: center; }
  .pricing .offers-section .title {
    font-size: 18px;
    color: #333333;
    font-weight: 600;
    margin-bottom: 10px; }
  .pricing .offers-section .offers-cards {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 24px 0 24px; }
    @media (min-width: 1280px) {
      .pricing .offers-section .offers-cards {
        flex-direction: row;
        align-items: normal; } }
  .pricing .offers-section .currency-info {
    max-width: 320px;
    font-size: 12px;
    margin: 0 auto;
    margin-bottom: 40px; }
    @media (min-width: 768px) {
      .pricing .offers-section .currency-info {
        max-width: 400px; } }

.pricing .business-offer-section {
  background-color: #008aae;
  padding: 64px 26px;
  text-align: center;
  color: white; }
  .pricing .business-offer-section .container {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;
    width: auto;
    height: auto; }
  .pricing .business-offer-section .header {
    color: #fff100;
    font-size: 27px;
    font-weight: 600;
    margin-bottom: 12px; }
  .pricing .business-offer-section .text1 {
    margin-bottom: 40px; }
  .pricing .business-offer-section .offer-banner {
    background-color: #006783;
    border-radius: 16px;
    font-size: 18px;
    margin: 0 auto;
    margin-top: 80px;
    margin-bottom: 80px;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 24px 0px 24px;
    max-width: 1280px; }
  .pricing .business-offer-section .offer-banner div {
    margin-bottom: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 40px;
    max-width: 434px; }
    .pricing .business-offer-section .offer-banner div .price {
      font-size: 56px;
      color: #fff100;
      font-weight: 600;
      margin-right: 10px; }
  .pricing .business-offer-section .business-nolimit {
    font-size: 24px;
    line-height: 50px; }
  .pricing .business-offer-section .business-cards {
    display: flex;
    margin: 50px 24px;
    flex-direction: column; }
    @media (min-width: 1280px) {
      .pricing .business-offer-section .business-cards {
        justify-content: center;
        flex-direction: row; } }
  .pricing .business-offer-section .business-card {
    color: white;
    display: flex;
    font-size: 14px;
    box-shadow: 0 0 10px rgba(0, 0, 10, 0.13);
    border-radius: 16px;
    background-color: #3ca5c5;
    flex-direction: column;
    text-align: left;
    flex: 2 1 auto;
    padding: 30px; }
    @media (min-width: 1280px) {
      .pricing .business-offer-section .business-card {
        max-width: 600px; } }
  .pricing .business-offer-section .business-card div {
    margin: 15px; }
  .pricing .business-offer-section .card-1 {
    margin-bottom: 30px; }
    @media (min-width: 1280px) {
      .pricing .business-offer-section .card-1 {
        margin-right: 12px;
        margin-bottom: 0; } }
  @media (min-width: 1280px) {
    .pricing .business-offer-section .card-2 {
      margin-left: 12px; } }
  .pricing .business-offer-section .business-btn {
    background-color: #fff100;
    border-radius: 5px;
    text-transform: none;
    color: #006783;
    padding: 5px 25px; }
  .pricing .business-offer-section .business-contact {
    font-size: 14px;
    margin-top: 20px; }

.pricing .included-in__heading, .pricing .functionalities__heading {
  text-align: center;
  color: #666666;
  font-weight: 700;
  margin: 50px 0 32px; }
  @media (min-width: 768px) {
    .pricing .included-in__heading, .pricing .functionalities__heading {
      font-size: 22px; } }

.pricing .static-hero {
  height: 248px;
  background-image: url("/static/home/pricing/img-pricing-hero-bg.jpg");
  background-repeat: repeat;
  background-size: auto;
  padding-top: 52px;
  padding-left: 50px;
  padding-right: 50px; }
  @media (min-width: 768px) {
    .pricing .static-hero {
      height: 327px;
      padding-top: 72px;
      padding-left: 100px;
      padding-right: 100px; } }
  @media (min-width: 1280px) {
    .pricing .static-hero {
      padding-top: 40px; } }

.pricing .columns {
  padding-top: 25px;
  padding-bottom: 16px;
  position: relative;
  float: none; }
  @media (min-width: 1280px) {
    .pricing .columns {
      padding-top: 18px;
      padding-bottom: 48px; } }
  .pricing .columns__arrow-container {
    position: absolute;
    width: 100%;
    top: 68px; }
    @media (min-width: 768px) {
      .pricing .columns__arrow-container {
        top: 65px; } }
    @media (min-width: 1280px) {
      .pricing .columns__arrow-container {
        top: 48px; } }
  .pricing .columns__arrow-wrapper {
    width: 215px;
    margin: 0 auto;
    position: relative; }
  .pricing .columns__arrow {
    right: -29px;
    top: 0;
    color: #008AAE;
    font-size: 10px;
    position: absolute;
    font-weight: 700; }
    @media (min-width: 768px) {
      .pricing .columns__arrow {
        font-size: 18px;
        right: -176px;
        letter-spacing: .4px; } }
    .pricing .columns__arrow::before {
      content: '';
      position: absolute;
      left: -17px;
      top: -8px;
      display: block;
      width: 16px;
      height: 17px;
      background-image: url("/static/home/pricing/pic-arrow1.svg");
      background-repeat: no-repeat;
      background-position: center; }
      @media (min-width: 768px) {
        .pricing .columns__arrow::before {
          background-image: url("/static/home/pricing/pic-arrow2.svg");
          width: 27px;
          height: 25px;
          left: -29px;
          top: -13px; } }
  .pricing .columns .tabs-container__tabs > div {
    width: 215px !important;
    background-color: #FFFFFF !important; }
    @media (min-width: 768px) {
      .pricing .columns .tabs-container__tabs > div {
        width: 305px !important; } }
  .pricing .columns .tabs-container__tabs > div:first-child {
    border-radius: 5px !important;
    border: 2px solid #008aae; }
    @media (min-width: 768px) {
      .pricing .columns .tabs-container__tabs > div:first-child {
        border-radius: 10px !important; } }
  .pricing .columns .tabs-container__tabs > div:nth-child(2) {
    margin: 0 auto 62px !important; }
    @media (min-width: 768px) {
      .pricing .columns .tabs-container__tabs > div:nth-child(2) {
        margin: 0 auto 42px !important; } }
    .pricing .columns .tabs-container__tabs > div:nth-child(2) > div {
      transform: translateX(500%) !important; }
      @media (min-width: 768px) {
        .pricing .columns .tabs-container__tabs > div:nth-child(2) > div {
          transform: translateX(715%) !important; } }
  .pricing .columns .tabs-container__tabs > div:nth-child(3) {
    width: 100% !important; }
  .pricing .columns .tabs-container__content {
    margin-top: 10px;
    width: 768px;
    max-width: none;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: row; }
    @media (max-width: 768px) {
      .pricing .columns .tabs-container__content {
        width: 100%;
        flex-direction: column; } }
  .pricing .columns .tabs-container__button {
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #008aae !important; }
    @media (min-width: 768px) {
      .pricing .columns .tabs-container__button {
        font-size: 16px !important; } }
    .pricing .columns .tabs-container__button > div > div {
      height: 25px !important; }
      @media (min-width: 768px) {
        .pricing .columns .tabs-container__button > div > div {
          height: 34px !important; } }
  .pricing .columns .tabs-container__button:not([style*="color: rgba(255, 255, 255, 0.7)"]) {
    color: #fff !important; }
  .pricing .columns .tabs-container__button:first-child {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important; }
    @media (min-width: 768px) {
      .pricing .columns .tabs-container__button:first-child {
        border-top-left-radius: 5px !important;
        border-bottom-left-radius: 5px !important; } }
  .pricing .columns .tabs-container__button:last-child {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important; }
    @media (min-width: 768px) {
      .pricing .columns .tabs-container__button:last-child {
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important; } }
  .pricing .columns .columns__column {
    margin-bottom: 50px;
    z-index: 1;
    width: 33.3%; }
    @media (max-width: 768px) {
      .pricing .columns .columns__column {
        width: 100%; } }
  .pricing .columns .columns--prior {
    z-index: 2;
    transform: scale(1.5); }
  .pricing .columns .columns__box {
    text-align: center;
    padding-bottom: 15px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    margin-bottom: 12px;
    background-color: #FFFFFF; }
    @media (min-width: 768px) {
      .pricing .columns .columns__box {
        margin-bottom: 23px; } }
  .pricing .columns .columns__features-header {
    text-align: center;
    margin-bottom: 16px; }
  .pricing .columns .columns__box-head {
    font-size: 17px;
    font-weight: 500;
    padding: 20px;
    border-bottom: 1px solid #F6F6F6;
    color: #666666; }
    @media (min-width: 768px) {
      .pricing .columns .columns__box-head {
        font-size: 14px;
        padding: 17px; } }
    .pricing .columns .columns__box-head--prior {
      color: #ffffff;
      background-color: #008aae; }
  .pricing .columns .columns__box-price {
    font-size: 54px;
    font-weight: 700;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333; }
    @media (min-width: 768px) {
      .pricing .columns .columns__box-price {
        font-size: 46px;
        padding: 18px 0 22px 23px; } }
  .pricing .columns .columns__box-dolar {
    font-size: 21px;
    font-weight: 500;
    padding-right: 5px;
    color: #4D4D4D; }
    @media (min-width: 768px) {
      .pricing .columns .columns__box-dolar {
        font-size: 18px; } }
  .pricing .columns .columns__box-per {
    font-size: 13px;
    text-align: left;
    font-weight: 500;
    padding-left: 5px;
    color: #808080; }
    @media (min-width: 768px) {
      .pricing .columns .columns__box-per {
        font-size: 11px; } }
  .pricing .columns .columns__box-description {
    line-height: 1.3em;
    min-height: 56px;
    margin-bottom: 10px;
    padding: 0 27px;
    color: #999999;
    letter-spacing: -.2px;
    font-weight: 500; }
    @media (min-width: 768px) {
      .pricing .columns .columns__box-description {
        font-size: 11px;
        padding: 0 32px; } }
  .pricing .columns .columns__button {
    margin-bottom: 18px;
    display: inline-block;
    background: #22b573;
    font-weight: 400; }
    @media (min-width: 768px) {
      .pricing .columns .columns__button {
        margin-bottom: 12px; } }
  .pricing .columns .columns__button:hover {
    transform: scale(1.05);
    color: white; }
  .pricing .columns .columns__destiny {
    font-size: 13px;
    font-weight: 500;
    padding-top: 10px;
    color: #333333;
    border-top: 1px solid #F6F6F6; }
    @media (max-width: 768px) {
      .pricing .columns .columns__destiny {
        padding: 14px 0 26px 0; } }
  .pricing .columns .columns__features {
    text-align: left;
    padding: 0 25px 0 25px; }
    @media (max-width: 768px) {
      .pricing .columns .columns__features {
        width: 250px;
        margin: 0 auto;
        padding: 0; } }
  .pricing .columns .columns__features-heading {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 15px;
    color: #4D4D4D;
    letter-spacing: 1px; }
    @media (min-width: 768px) {
      .pricing .columns .columns__features-heading {
        font-size: 15px;
        letter-spacing: 0;
        margin-bottom: 10px; } }
    @media (min-width: 1280px) {
      .pricing .columns .columns__features-heading {
        margin-bottom: 14px; } }
  .pricing .columns .columns__features-list {
    padding-left: 15px; }
  .pricing .columns .columns__features-item {
    color: #666666;
    font-size: 12px;
    margin-bottom: 15px; }
    .pricing .columns .columns__features-item:last-child {
      margin-bottom: 0; }
    @media (min-width: 768px) {
      .pricing .columns .columns__features-item {
        font-size: 13px;
        margin-bottom: 19px; }
        .pricing .columns .columns__features-item::before {
          width: 6px;
          height: 6px;
          top: 3px;
          left: -15px; } }
    @media (min-width: 1280px) {
      .pricing .columns .columns__features-item {
        margin-bottom: 19px; } }

.pricing .spinner {
  border-left: 1.1em solid #333333;
  margin: 10px; }

.pricing .business-model {
  text-align: center;
  background-image: url("/static/home/pricing/img-businessmodel-bg.jpg");
  background-position: center;
  padding-top: 50px;
  padding-bottom: 40px; }
  @media (min-width: 768px) {
    .pricing .business-model {
      padding-top: 100px;
      padding-bottom: 80px;
      text-align: left; } }
  @media (min-width: 1280px) {
    .pricing .business-model {
      padding-top: 109px;
      padding-bottom: 92px; } }
  .pricing .business-model__container {
    max-width: 738px;
    margin: 0 auto; }
    @media (min-width: 768px) {
      .pricing .business-model__container {
        display: flex; } }
    .pricing .business-model__container > svg {
      width: 79px;
      height: 67px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px; }
      @media (min-width: 768px) {
        .pricing .business-model__container > svg {
          width: 90px;
          height: 80px;
          margin: 28px 0 0 13px; } }
      @media (min-width: 1280px) {
        .pricing .business-model__container > svg {
          margin: 21px 0 0 62px; } }
  .pricing .business-model__content {
    padding: 0 30px; }
    @media (min-width: 768px) {
      .pricing .business-model__content {
        padding: 0 30px 0 58px; } }
    @media (min-width: 1280px) {
      .pricing .business-model__content {
        padding: 0 60px 0 55px; } }
  .pricing .business-model__heading {
    color: #ffffff;
    margin-bottom: 8px; }
    @media (min-width: 768px) {
      .pricing .business-model__heading {
        font-size: 26px; } }
  .pricing .business-model__subheading {
    color: #ffffff;
    margin-bottom: 18px; }
    @media (min-width: 768px) {
      .pricing .business-model__subheading {
        line-height: 1.3em;
        font-size: 15px; } }
    @media (min-width: 1280px) {
      .pricing .business-model__subheading {
        font-size: 13px;
        line-height: 1.5em; } }
  @media (min-width: 768px) {
    .pricing .business-model__button {
      padding: 4px 8px;
      margin-top: 53px; } }
  @media (min-width: 1280px) {
    .pricing .business-model__button {
      margin-top: 46px; } }

.pricing .included-in {
  padding-top: 32px;
  max-width: 765px;
  height: 600px;
  display: block;
  padding-bottom: 45px; }
  @media (min-width: 1280px) {
    .pricing .included-in {
      max-width: 930px; } }
  @media (max-width: 768px) {
    .pricing .included-in {
      height: 650px; } }
  .pricing .included-in__content {
    width: 100%; }
    @media (min-width: 768px) {
      .pricing .included-in__content {
        padding: 0; } }
  .pricing .included-in__box {
    width: 33%;
    height: 200px;
    float: right;
    padding: 50px; }
    @media (max-width: 768px) {
      .pricing .included-in__box {
        display: inline-block;
        width: 100%;
        height: 80px;
        padding: 0 20%; } }
    @media (max-width: 479px) {
      .pricing .included-in__box {
        padding: 0 10%; } }
  .pricing .included-in__box-pic {
    min-width: 50px;
    text-align: center; }
    @media (max-width: 768px) {
      .pricing .included-in__box-pic {
        float: left;
        margin-right: 7.5px; } }
    .pricing .included-in__box-pic > svg {
      max-height: 42px;
      width: auto;
      stroke-width: 2px; }
  .pricing .included-in__box-desc {
    line-height: 1.2em;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 0; }
    @media (max-width: 768px) {
      .pricing .included-in__box-desc {
        text-align: left;
        font-size: 12px;
        margin-left: 15px; } }
  .pricing .included-in h4 {
    margin-bottom: 32px; }

@media (min-width: 768px) {
  .pricing .functionalities {
    padding-top: 50px; } }

.pricing .functionalities__wrapper {
  background-color: #F4F4F4;
  padding-top: 15px;
  padding-bottom: 2px;
  width: 100%;
  display: block; }

.pricing .functionalities__content {
  max-width: 810px;
  margin: 40px auto; }
  @media (min-width: 750px) {
    .pricing .functionalities__content {
      display: flex;
      justify-content: space-around; } }

.pricing .functionalities__box {
  margin-bottom: 55px;
  text-align: center; }

.pricing .functionalities__box-img {
  margin: 0 auto 6px 0;
  max-width: 40%; }
  @media (min-width: 768px) {
    .pricing .functionalities__box-img {
      max-width: 100%;
      margin: 0 auto 10px 0; } }

.pricing .functionalities__box-desc {
  max-width: 175px;
  margin: 0 auto;
  line-height: 1.9em;
  font-weight: 500; }
  @media (min-width: 768px) {
    .pricing .functionalities__box-desc {
      font-size: 14px;
      line-height: 1.35em; } }

.pricing .faq {
  padding-top: 25px;
  padding-bottom: 8px;
  max-width: 500px; }
  @media (min-width: 768px) {
    .pricing .faq {
      max-width: 770px;
      padding-top: 60px;
      padding-right: 55px;
      padding-bottom: 17px;
      padding-left: 55px; } }
  @media (min-width: 1280px) {
    .pricing .faq {
      max-width: 850px; } }
  .pricing .faq__heading {
    margin-bottom: 25px; }
    @media (min-width: 768px) {
      .pricing .faq__heading {
        margin-bottom: 65px; } }
  .pricing .faq__container {
    padding: 0 15px; }
    @media (min-width: 768px) {
      .pricing .faq__container {
        padding: 0;
        display: grid;
        grid-template-columns: 50% 50%; } }
  .pricing .faq__case {
    text-align: center;
    margin-bottom: 30px; }
    @media (min-width: 768px) {
      .pricing .faq__case {
        text-align: left;
        display: inline-block;
        margin-bottom: 50px; }
        .pricing .faq__case:nth-child(even) {
          padding-left: 45px; }
        .pricing .faq__case:nth-child(odd) {
          padding-right: 45px; } }
    @media (min-width: 1280px) {
      .pricing .faq__case {
        margin-bottom: 66px; } }
  .pricing .faq__question {
    color: #4D4D4D;
    margin-bottom: 6px; }
    @media (min-width: 768px) {
      .pricing .faq__question {
        font-size: 15px;
        margin-bottom: 15px; } }
  .pricing .faq__answer {
    color: #808080;
    font-size: 11px;
    line-height: 1.3em; }
    @media (min-width: 768px) {
      .pricing .faq__answer {
        font-size: 13px;
        line-height: 1.4em; } }

.pricing .more-questions {
  padding-top: 22px;
  padding-bottom: 24px;
  background-color: #006783; }
  @media (min-width: 768px) {
    .pricing .more-questions {
      padding-top: 57px;
      padding-bottom: 55px; } }
  .pricing .more-questions__heading {
    text-align: center;
    color: #ffffff;
    margin-bottom: 18px;
    font-weight: 400; }
    @media (min-width: 768px) {
      .pricing .more-questions__heading {
        font-size: 22px;
        margin-bottom: 25px; } }
  .pricing .more-questions__buttons {
    display: flex;
    justify-content: space-between;
    max-width: 270px;
    margin: 0 auto; }
    @media (min-width: 768px) {
      .pricing .more-questions__buttons {
        max-width: 470px; } }
