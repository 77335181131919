body::before {
  content: "(xsm: 479px, sm: 750px, md: 768px, lg: 970px, xl: 1280px)";
  display: none; }

.not-found-page h1 {
  padding: 80px 20px;
  color: #1A1A1A;
  text-align: center; }

@media (min-width: 769px) {
  .not-found-page h1 {
    padding: 120px 20px; } }
